<template>
      <div @mousedown="makeActive()" class="cube" :class="isActive ? 'active-pane' : ''" :style="cssVars">
          <div class="pane top" :class="darkMode? 'dark-mode' : ''"></div>
          <div class="pane pane-content bottom" ref="contentPane" :class="[isActive ? 'active-front-pane' : '', darkMode? 'dark-mode' : '']">
              <div class="content-wrapper" v-html="this.htmlContent"></div>
          </div>
          <div class="pane left" :class="darkMode? 'dark-mode' : ''"></div>
          <div class="pane right" :class="darkMode? 'dark-mode' : ''"></div>
          <div class="pane back" :class="darkMode? 'dark-mode' : ''"></div>
          <div id="" ref="front" class="pane pane-content front" :class="darkMode? 'dark-mode' : ''">
              <span>{{content}}</span>
          </div>
      </div>
</template>

<script>
    import jsonContent from '../../storage/content.json';
    import {ref} from "vue";

    export default {
        name: "Cube",
        props: ['name', 'posx', 'posy', 'posz', 'rotx', 'roty', 'rotz', 'content', 'colorBase', 'calcDistance', 'startZ', 'darkMode'],
        data: function() {
            return {
                isActive: false,
                size: 200,
                jsonContent: jsonContent,
                htmlContent: null,
                closeFallOffDist: 700,
            }
        },
        computed: {
            cssVars () {
                return {
                    '--z-index': this.calculateOutput(this.posz * -1),
                    //'--display': this.posz < this.closeFallOffDist || this.posz *-1 <= this.$parent.$data.maxZDist ? 'block' : 'none',
                    '--pos-X': this.posx + "vw",
                    '--pos-Y': this.posy + "vh",
                    '--pos-Z': this.posz + 'px',
                    '--rot-X': this.rotx + "deg",
                    '--rot-Y': this.roty + "deg",
                    '--rot-Z': this.rotz + "deg",
                    '--window-height': window.innerHeight + 'px',
                    '--rand-anim-x': this.randInt() + "px",
                    '--rand-anim-y': this.randInt() + "px",
                    '--rand-anim-rot-x': (this.rotx + (this.randInt() * 0.1)) + "deg",
                    '--rand-anim-rot-y': (this.roty + (this.randInt() * 0.1)) + "deg",
                    '--rand-anim-rot-z': (this.rotz + (this.randInt() * 0.1)) + "deg",
                    '--rand-time': this.randTime() + "s",
                    '--color-base': 'rgb(' + this.colorBase[0] + ',' + this.colorBase[1] + ',' + this.colorBase[2] + ')',
                    '--color-semi-dark': 'rgb(' + (this.colorBase[0] - 25) + ',' + (this.colorBase[1] - 25) + ',' + (this.colorBase[2] - 25) + ')',
                    '--color-dark': 'rgb(' + (this.colorBase[0] - 40) + ',' + (this.colorBase[1] - 40) + ',' + (this.colorBase[2] - 40) + ')',
                    '--color-light': 'rgb(' + (this.colorBase[0] + 70) + ',' + (this.colorBase[1] + 70) + ',' + (this.colorBase[2] + 70) + ')',
                    '--cube-size': this.size + "px",
                    '--distance': Math.floor(this.$parent.calculatedDistance - 130) + 'px',

                }
            }
        },
        methods: {
            calculateOutput: function(x) {
              let min = this.$parent.$data.maxZDist * -1
              let max = 600
              let normalizedX = ((x - min) / (max - min)) * 100
              return Math.floor(200 / normalizedX * 1000);
            },
            randInt: function() {
              var flipInt = Math.random()
              if(flipInt > 0.5) {
                return Math.floor(Math.random() * 50) + 1
              } else {
                return -(Math.floor(Math.random() * 50) + 1)
              }
            },
            randTime: function() {
              return Math.floor(Math.random() * 20) + 5
            },
            makeActive() {
                this.$parent.setActiveCube(this);
                this.$router.replace('/'+this.name)
                this.$emit('activate');
                this.isActive = true;
            },
            makeInactive() {
                this.$parent.activeCube = null;
                this.$emit('deactivate');
                this.isActive = false;
            },
            loadContent() {
                var html = require('@/html-content/'+ this.jsonContent[this.name])
                html = html.replaceAll('dark-mode', this.darkMode ? 'dark' : 'light');
                this.htmlContent = html;
            }
        },
        mounted() {
          this.loadContent();

          //emit event to parent
          this.$emit('contentLoaded');
        },
        setup() {
          const contentPane = ref(null);
          return { contentPane }
        },
        watch: {
            darkMode: function() {
                this.loadContent();
                this.$parent.$parent.loadAvatar();
            }
        }
    }
</script>

<style scoped>
    .cube {
        display: var(--display);
        z-index: var(--z-index);
        position: absolute;
        overflow:visible;
        width: var(--cube-size);
        height: var(--cube-size);
        transition: all 0.8s cubic-bezier(0.34, 1.20, 0.64, 1);
        -webkit-transform-style: preserve-3d;
        transform-origin: center center 0;
        transform-style: preserve-3d;
        transform:
            translate3d(var(--pos-X), var(--pos-Y), var(--pos-Z))
            rotateX(var(--rot-X))
            rotateY(var(--rot-Y))
            rotateZ(var(--rot-Z));
    }

    .pane {
        position: absolute;
        width: var(--cube-size);
        height: var(--cube-size);
        border: 0px solid black;
        outline-offset: -1px;
        transition: all 0.5s ease-in-out;
        border-radius: 5px;
    }

    .pane-content {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        color:black;
        line-height: calc(var(--cube-size) / 3.2);
        text-align: center;
        font-size: calc(var(--cube-size) / 2.2);
        overflow-wrap: break-word;
        overflow: visible;
    }

    .pane span{
        max-width: var(--cube-size);
        transition: all 0.5s ease;
    }

    .active-front-pane span {
        font-size: 20px;
    }

    .pane p {
        font-size: 8px;
        line-height: 0.75rem;
        opacity: 0;
    }

    .wrapper{
        position: absolute;
        transform-style: preserve-3d;
        z-index: 9995;
        transform: translate3d(0,0,0);
    }

    .cube .pane.dark-mode:not(.pane-content) {
        filter: brightness(0.1);
    }

    .cube .pane-content.dark-mode {
        background-color: #1a1a1a !important;
        color:white !important;
    }

    /*.left::after {
      content: '';
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background: linear-gradient(to bottom, var(--color-light), var(--color-dark));
      opacity: 0.3;
    }*/

    /*figure { -webkit-transform-origin: 50% 50% 0 }*/
    .front { -webkit-transform: translateZ(calc(var(--cube-size) / 2)); background-color: var(--color-base);
    }
    .top { -webkit-transform: rotateX(90deg) translateZ(calc(var(--cube-size) / 2)); background-color: var(--color-light); border-top: 0px solid black;
    }
    .bottom { -webkit-transform: rotateX(-90deg) translateZ(calc(var(--cube-size) / 2)); background-color: var(--color-dark); border-bottom: 0px solid black;
    }
    .left { -webkit-transform: rotateY(-90deg) translateZ(calc(var(--cube-size) / 2)); background-color: var(--color-semi-dark); border-left: 0px solid black;
    }
    .right { -webkit-transform: rotateY(90deg) translateZ(calc(var(--cube-size) / 2)); background-color: var(--color-semi-dark); border-right: 0px solid black;
    }
    .back { -webkit-transform: rotateY(180deg) translateZ(calc(var(--cube-size) / 2)); background-color: var(--color-dark); border: 0px solid black;
    }

    .active-front-pane {
        cursor: default;
        box-sizing: border-box;
        background-color: var(--color-base) !important;
    }

    .active-front-pane .content-wrapper {
        opacity: 1;
        z-index: 9999 !important;
    }

    .cube:hover {
      cursor: pointer;
      transform: translate3d(var(--pos-X), var(--pos-Y), calc(var(--pos-Z) + 150px)) rotateX(var(--rot-X)) rotateY(var(--rot-Y)) rotateZ(var(--rot-Z));
    }

    .active-pane {
      z-index: 9999 !important;
      cursor: default;
      transform: translate3d(50vw, 50vh, var(--distance)) translate(-50%, -50%) rotateY(360deg) rotateX(-270deg) !important;
    }

    .active-pane .pane {
      border:none;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .1s
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0
    }

    .content-wrapper {
      text-align: start;
      padding: 15px;
      width: 100%;
      height: 100%;
      font-size: 10px;
      line-height: 11px;
      zoom: 0.5;
      opacity: 0;
      transition: opacity 0.5s ease;
    }
</style>