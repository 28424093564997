<template>
  <div id="stage" class="stage" :style="cssVars" :class="darkMode? 'dark-mode' : ''">
    <Settings
        :amount-of-cubes="amountOfAtmosCubes"
        :perspective="perspectiveValue"
        :render-distance="maxZDist"
        :spawn-distance="maxSpawnDistance"
        :hue="hue"
        :hue-offset="hueOffset"
        :saturation="saturation"
        :lightness="lightness"
        :dark-mode="darkMode"
        @update:amount-of-cubes="amountOfAtmosCubes = $event"
        @update:perspective="perspectiveValue = $event"
        @update:maxZDist="maxZDist = $event"
        @update:spawnDistance="maxSpawnDistance = $event"
        @update:hue="hue = Number($event)"
        @update:hueOffset="hueOffset = Number($event)"
        @update:saturation="saturation = Number($event)"
        @update:lightness="lightness = Number($event)"
        @update:dark-mode="darkMode = Boolean($event)"
        @reRender="renderAtmosCubes(true, true)"
    ></Settings>
    <AtmosCube
      v-for="(cube, index) in atmosCubes" :key="index"
      :posx="cube.posx"
      :posy="cube.posy"
      :posz="cube.posz"
      :rotx="cube.rotx"
      :roty="cube.roty"
      :rotz="cube.rotz"
      :start-z="cube.startZ"
      :color-base="cube.colorBase"
      :blur-amount="cube.blurAmount"
      :dark-mode="darkMode"
      :transition-mode="cube.transitionMode"
    ></AtmosCube>

    <Cube
      ref="cubes"
      v-for="cube in contentCubes"
      :key="cube.name" :content="cube.name"
      :name="cube.name"
      :posx="cube.posx"
      :posy="cube.posy"
      :posz="cube.posz"
      :rotx="cube.rotx"
      :roty="cube.roty"
      :rotz="cube.rotz"
      :start-z="cube.startZ"
      :color-base="cube.colorBase"
      :dark-mode="darkMode"
      @activate="activateBackgroundPane"
      @deactivate="deactivateBackgroundPane"
      @contentLoaded="$emit('contentLoaded')"
    ></Cube>

    <div id="distCalc" ref="distCalcCube"></div>

    <div id="bg-pane" @click="deactivateAllCubes()"></div>

  </div>
</template>

<script>
import jsonContent from '../../storage/content.json';
import Cube from './Cube.vue'
import AtmosCube from "@/vuejs/components/AtmosCube.vue";
import {ref} from "vue";
import Settings from "@/vuejs/components/Settings.vue";

export default {
  name: 'Stage',
  props: {
    msg: String
  },
  data: function() {
    return {
      cameraX: 0,
      scrollAmount: 0,
      atmosCubes: [],
      contentCubes: [],
      activeCube: null,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      updatePerspective: false,
      test: [],
      timer: null,
      mouseX: window.innerWidth / 2,
      mouseY: window.innerHeight / 2,
      perspectiveValue: 500,
      startingPerspective: 0,
      calculatedDistance: 0,
      jsonContent: jsonContent,
      amountOfAtmosCubes: 100,
      maxZDist: 15000,
      minZDist: 3000,
      maxSpawnDistance: 20000,
      perspectiveOrigin: "50% 50%",
      tickCount: 0,
      hue: 180,
      hueOffset: 180,
      saturation: 70,
      lightness: 70,
      darkMode: false,
    }
  },
  components: {
    Settings,
    AtmosCube,
    Cube
  },
  computed: {
    cssVars() {
      return{
        '--perspective': this.perspectiveValue + 'px',
        '--perspective-origin': this.perspectiveOrigin,
      }
    }
  },
  created () {
    if(window.mobileCheck()) {
      this.amountOfAtmosCubes = Math.floor(this.amountOfAtmosCubes * 0.25)
    }

    this.pages = Object.keys(this.jsonContent);

    this.renderAtmosCubes()
    for (let z = 0; z < this.pages.length; z++) {
      let posx, posy;
      let isClose, tries = 0;

      do {
        posx = this.randInt(0, 80);
        posy = this.randInt(0, 70);
        isClose = false;

        for (let i = 0; i < this.contentCubes.length; i++) {
          if (Math.abs(this.contentCubes[i].posx - posx) < 30 && Math.abs(this.contentCubes[i].posy - posy) < 30) {
            isClose = true;
            break; // Found a close one, no need to check more
          }
        }

        if (isClose) {
          tries++;
          if (tries >= 100) {
            break; // Avoid infinite loop by breaking after 100 tries
            //TODO: remove breaking by improving algorithm for finding best positions
          }
        }
      } while (isClose);

      var mobilePos = [[0, 60], [50, -10], [100, 30]]

      let posz = -200 + -(z * 500);
      // Only add the cube if a suitable position was found
      if (!isClose) {
        this.contentCubes.push({
          name: this.pages[z],
          posx: window.mobileCheck() ? mobilePos[z][0] : posx,
          posy: window.mobileCheck() ? mobilePos[z][1] : posy,
          posz: posz,
          startZ: posz,
          rotx: this.randRot(),
          roty: this.randRot(),
          rotz: this.randRot(),
          colorBase: [255, 255, 255],
        });
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      window.addEventListener('mousemove', this.handleMouseMove)
      window.addEventListener('wheel', this.handleScrollWheel)
    });
    this.timer = setInterval(
      this.checkActivePane
    , 100);

    setTimeout(() => {
      this.calculateContentPaneDistance();
    }, 500);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('wheel', this.handleScrollWheel)
  },
  methods: {
    // big: [],
    generateNiceColor(prevColor = null) {
        let hue = this.randInt(this.hue, this.hue + this.hueOffset);
        // Set saturation to 70% to ensure vivid colors
        let saturation = this.saturation;
        // Set lightness to 50% to avoid extremes of dark or light
        let lightness = this.lightness;

        if(prevColor) {
            let hsl = this.rgbToHsl(prevColor[0], prevColor[1], prevColor[2]);
            hue = hsl[0];
        }

        // Convert HSL to RGB
        return this.hslToRgb(hue, saturation, lightness);
    },
    renderAtmosCubes(amountChanged = true, colorsShouldChange = false) {
        var zDist = this.maxSpawnDistance;
        //delete all cubes
        if(amountChanged) {
            this.atmosCubes = [];

            for (var i = 0; i < this.amountOfAtmosCubes; i++) {
                var posz = this.randInt(1000, zDist)
                var posx = this.randInt(-150, 250)
                var posy = this.randInt(-200, 200)
                var rotx = this.randRot()
                var roty = this.randRot()
                var rotz = this.randRot()

                this.atmosCubes.push({
                    posz: -(posz),
                    startZ: -(posz),
                    posx: posx,
                    posy: posy,
                    rotx: rotx,
                    roty: roty,
                    rotz: rotz,
                })
                this.atmosCubes[[i]].colorBase = this.generateNiceColor();
                this.atmosCubes[[i]].blurAmount = (this.atmosCubes[[i]].posz * -1) / zDist
            }
        } else {
            for(var x = 0; x < this.atmosCubes.length; x++) {
                this.atmosCubes[x].colorBase = this.generateNiceColor(colorsShouldChange ? null : this.atmosCubes[x].colorBase);
                this.atmosCubes[x].blurAmount = (this.atmosCubes[[x]].posz * -1) / zDist
            }
        }
    },
    hslToRgb(h, s, l) {
      // Convert HSL to RGB using the formula
      s /= 100;
      l /= 100;
      let c = (1 - Math.abs(2 * l - 1)) * s;
      let x = c * (1 - Math.abs((h / 60) % 2 - 1));
      let m = l - c/2;
      let r, g, b;
      if (h < 60) {
        r = c, g = x, b = 0;
      } else if (h < 120) {
        r = x, g = c, b = 0;
      } else if (h < 180) {
        r = 0, g = c, b = x;
      } else if (h < 240) {
        r = 0, g = x, b = c;
      } else if (h < 300) {
        r = x, g = 0, b = c;
      } else {
        r = c, g = 0, b = x;
      }
      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);
      return [r, g, b];
    },
    rgbToHsl(r, g, b) {
        // Convert RGB values from 0–255 to 0–1
        r /= 255;
        g /= 255;
        b /= 255;

        // Find the maximum and minimum values among r, g, b
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);

        // Calculate lightness
        let h, s, l = (max + min) / 2;

        // If the max and min are the same, it's a shade of gray
        if (max === min) {
            h = 0; // Hue is 0 when it's gray
            s = 0; // Saturation is 0 when it's gray
        } else {
            const delta = max - min;

            // Calculate saturation
            s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);

            // Calculate hue in degrees
            switch (max) {
                case r:
                    h = ((g - b) / delta + (g < b ? 6 : 0)) * 60;
                    break;
                case g:
                    h = ((b - r) / delta + 2) * 60;
                    break;
                case b:
                    h = ((r - g) / delta + 4) * 60;
                    break;
            }
        }

        // Round the results and format them
        h = Math.round(h);
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return [h, s, l];
    },
    onResize() {
      this.calculateContentPaneDistance();
      this.windowWidth = window.innerWidth;
    },
    randInt: function(min = -10, max = 100) {
      // Ensure the range is valid
      if(min >= max) {
        throw new Error('min must be less than max');
      }

      var range = max - min;

      // while(num in this.big) {
      //   num = Math.floor(Math.random() * (range + 1)) + min;
      // }

      return Math.floor(Math.random() * (range + 1)) + min;
    },
    randRot: function() {
      var flipInt = Math.random()
      if(flipInt > 0.5) {
        return Math.floor(Math.random() * 20) + 1
      } else {
        return -(Math.floor(Math.random() * 20) + 1)
      }
    },
    checkActivePane: function() {
      this.tickCount++;
      this.updatePerspective = (this.activeCube === null);

      this.updateCameraPos();
      this.updateAtmosCubes();
      this.updateCubes();
      // only update every 10th frame
    },
    deactivateAllCubes() {
      this.cubes.forEach(cube => {
        if(cube.isActive) {
          cube.makeInactive();
        }
      });
      this.deactivateBackgroundPane();
    },
    handleMouseMove(event) {
     this.mouseX = event.clientX;
     this.mouseY = event.clientY;
    },
    handleScrollWheel(event) {
      this.scrollAmount -= event.deltaY;
    },
    updateCameraPos() {
      var scope = 5;
      var base = 47.5;
      if(this.updatePerspective) {
        var x = (base + ((this.mouseX / this.windowWidth) * scope))
        var y = (base + ((this.mouseY / this.windowHeight) * scope))

        this.perspectiveOrigin = x + "% " + y + "%";
      } else {
        this.perspectiveOrigin = "50% 50%";
      }
    },
    updateAtmosCubes() {
      for (var i = 0; i < this.atmosCubes.length; i++) {
        let newz = 0;

        if(this.tickCount % 5 === 0) {
          newz = this.atmosCubes[i].startZ - this.tickCount * 50;
        } else {
          newz = this.atmosCubes[i].posz;
        }


        // Use modulo to wrap the position for infinite scrolling
        newz = ((newz - this.minZDist) % (this.maxSpawnDistance - this.minZDist)) + this.minZDist;


        if(Math.abs(newz - this.atmosCubes[i].posz) > (this.maxSpawnDistance * 0.2)) {
          this.atmosCubes[i].colorBase = this.generateNiceColor();
          this.atmosCubes[i].transitionMode = 'transform 0s none, opacity 0.1s linear';
        } else {
          this.atmosCubes[i].transitionMode = 'transform 2.1s linear, opacity 0.1s linear';
        }

        this.atmosCubes[i].posz = newz;

        // Calculate and update the blur amount based on z-position
        this.atmosCubes[i].blurAmount = (this.atmosCubes[i].posz * -1) / this.maxZDist;
      }
    },
    updateCubes() {
      // for(var i = 0; i < this.contentCubes.length; i++) {
      //   let newz = this.contentCubes[i].startZ + this.scrollAmount;
      //
      //   this.contentCubes[i].posz = newz;
      // }
    },
    setActiveCube(cube) {
      this.cubes.forEach(cube => {
        console.log(cube.isActive, 'cube.isActive')
        if(cube.isActive) {
          cube.makeInactive();
        }
      });
      this.activeCube = cube;
    },
    activateBackgroundPane() {
      var bgPane = document.getElementById("bg-pane");
      bgPane.classList.add("bg-pane-active");
    },
    deactivateBackgroundPane() {
      var bgPane = document.getElementById("bg-pane");
      bgPane.classList.remove("bg-pane-active");
    },
    calculateContentPaneDistance() {
      var w = window.innerWidth;
      var h = window.innerHeight;
      var aspect = w / h;
      var dimension = aspect >= 1 ? h : w;
      var initSize = 200;
      var requiredScale = dimension / initSize;
      this.calculatedDistance = this.perspectiveValue - (this.perspectiveValue / requiredScale);
    }
  },
  setup() {
    const cubes = ref([]);
    const distCalcCube = ref(null);
    return { cubes, distCalcCube }
  },
  watch: {
    amountOfAtmosCubes: function() {
      this.renderAtmosCubes()
    },
    perspectiveValue: function() {
        this.calculateContentPaneDistance();
    },
    maxSpawnDistance: function() {
      this.renderAtmosCubes()
    },
    hue: function() {
      this.renderAtmosCubes(false, true)
    },
    hueOffset: function() {
      this.renderAtmosCubes(false, true)
    },
    saturation: function() {
      this.renderAtmosCubes(false)
    },
    lightness: function() {
      this.renderAtmosCubes(false)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .stage {
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    background: rgb(24, 17, 93);
    background: radial-gradient(circle at var(--perspective-origin), rgb(255 255 255) 0%, rgb(252 193 246) 25%, rgb(188 183 255) 51%, rgb(38 130 255) 100%);
    width: 100%;
    height: 100%;
    -webkit-perspective: var(--perspective);
    -webkit-perspective-origin: var(--perspective-origin);
    overflow: hidden;
    transition: all 0.3s !important;
  }
  .stage.dark-mode {
      background: radial-gradient(circle at var(--perspective-origin), rgb(158 94 197) 0%, rgb(105 43 98) 25%, rgb(35 31 100) 51%, rgb(0 0 0) 100%);
  }

  #myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100vw;
    height:100vh;
    object-fit: cover;
    mix-blend-mode: multiply;
    opacity: 0.2;
  }

  .bg-pane-active {
    cursor: default;
    z-index: 300;
    transform: translate3d(-1800px, -210px, -200px) rotateX(0deg);
    transform-style: preserve-3d;
    position: relative;
    height: 9999px;
    width: 9999px;
    overflow: hidden;
  }
</style>
