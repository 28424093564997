<template>
  <div id="app">
    <Stage
        @contentLoaded="loadAvatar"
    ></Stage>
  </div>
</template>

<script>
import Stage from './vuejs/components/Stage.vue'
import {loadAvatar as _loadAvatar} from "@/js/util";

require('./js/util.js')

export default {
  name: 'App',
  data: function () {
    return {
      mousePosX: 0
    }
  },
  components: {
    Stage
  },
    methods: {
        loadAvatar: function () {
            _loadAvatar()
        }
    },
}

</script>

<style>
#app {
  position: absolute;
  top:0;
  left:0;
  width: 100%; height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#avatar {
    display: none;
}
/* wide screen only */
@media (min-width: 1024px) {
  #avatar {
    display: block;
    position: absolute;
    top: 3.3rem;
    right: 1.5rem;
    height: 104px;
    border-radius: 50%;
  }
}

a {
  color: #916cff;
  text-decoration: none;
}
a:hover {
  color: #b093ff;
}
a:active {
  color: #6143c3;
}
a i {
  vertical-align: -webkit-baseline-middle;
  margin-right: 0.25rem;
}
.purple-text {
    color: #ae6cff;
}
</style>
